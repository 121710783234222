.CallToAction{
    padding: 0px 10px 10px 10px ;
    .contentCallToAction{
        @include bg-blur($accent-color, $accent-color);
        padding: 70px;
        border-radius: 20px;
    }
    button{
        margin: auto;
    }

}
@media (max-width: 767px){
    .CallToAction{
        .contentCallToAction{
            padding: 60px 10px;
        }
    }
}