// .slick-arrow.slick-next.custom-next-arrow {
//   background: $background-color-secondary;
//   border-radius: 50%;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   width: 30px;
//   height: 30px;
// }

.custom-prev-arrow:before,
.custom-next-arrow:before {
  font-size: 30px;
  color: $background-color-secondary;
  
}
.arrowSlider {
  color: $accent-color;
  background: $background-color-secondary;
  padding: 6px;
  border-radius: 90px;
  transform: 300ms;
  &:hover{
    background-color: $accent-color;
    color: $title-color;
  }
}
.slick-prev:before, .slick-next:before {
  content: none;
}
.slick-dots li button:before {
  margin-top: 10px;
  font-size: 15px;
  color: #000000;
}

.slick-dots li.slick-active button:before {
  color: #000000;
}
.slick-next {
  right: 0px;
}
.slick-prev {
  left: -15px;
  z-index: 2;
}

@media(max-width: 767px){
  .slick-prev {
    left: 0px!important;
    z-index: 2;
  }
  .slick-next {
    right: 12px;
  }
}