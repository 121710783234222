
#hero{
    padding: 0px 10px 10px 10px ;
    .contentHero{
        background-color: $background-color-secondary;
        overflow: hidden;
        position: relative;
        height: 600px;
        display: flex;
        justify-content: center;
        align-items: center;
        @include bg-blur($accent-color, $accent-color);
        border-radius: 15px;
        position: relative;
    }
    .container{
        display: flex;
        flex-direction: column;
        gap: 10px;
        justify-content: start;
        align-items: start;
        
        h1{
            color: $title-color-white;
            text-align: start;
            width: 100%;
            // margin-top: 70px;
        }
        p{
            width: 100%;
            text-align: start;
        }
        .contentBtn{
            display: flex;
            gap: 20px;

        }
    }
    .drh{
        
    }
    .heroImg1{
        position: absolute;
        z-index: 5;
        bottom: 20px;
        right: 10%;
        box-shadow: 0 1.6rem 3rem rgb(113 113 113 / 18%) !important;
        will-change: transform;
        border-radius: 8.18934px;
        transform: rotate3d(1, 1, -1, 45deg) translate3d(-87.72px, 47.378px, 200px);
        animation: float-horizontal-1 5s infinite;
    }
    .heroImg2{
        position: absolute;
        z-index: 4;
        bottom: 90px;
        right: 8%;
        box-shadow: 0 1.6rem 3rem rgb(113 113 113 / 18%) !important;
        transform: rotate3d(1, 1, -1, 24.7844deg) translate3d(546.38px, -20.4159px, 100px);
        border-radius: 8.18934px;
        transform: rotate3d(1, 1, -1, 45.7844deg) translate3d(-9.62px, -20.4159px, 100px);
        animation: float-horizontal-1 8s infinite;
    }
    .heroImg3{
        position: absolute;
        z-index: 3;
        bottom: 81px;
        right: 37%;
        box-shadow: 0 1.6rem 3rem rgb(113 113 113 / 18%) !important;
        transform: rotate3d(1, 1, -1, 45deg) translate3d(476.438px, -286.44px, 22px);
         border-radius: 8.18934px;
         animation: float-horizontal-1 12s infinite;
    }
    .heroImg4{
        position: absolute;
        z-index: 2;
        right: 12%;
        bottom: 125px;
        box-shadow: 0 1.6rem 3rem rgb(113 113 113 / 18%) !important;
        transform: rotate3d(1, 1, -1, 45deg) translate3d(0%, 0%, 10px);
         border-radius: 8.18934px;
         animation: float-horizontal-1 15s infinite;
    }
    .heroImg5{
        position: absolute;
        z-index: 1;
        bottom: 330px;
        right: 30%;   
        box-shadow: 0 1.6rem 3rem rgb(113 113 113 / 18%) !important; 
        transform: rotate3d(1, 1, -1, 45deg) translate3d(-221.76px, -647.378px, 21px);
         border-radius: 8.18934px;
         animation: float-horizontal-1 22s infinite;
    }

}

@keyframes float-horizontal-1 {
    0%{
        transform: rotate3d(1, 1, -1, 45deg) translate3d(-87.72px, 47.378px, 200px);
    }
    50%{
        transform: rotate3d(1, 1, -1, 45deg) translate3d(-107.72px, 47.378px, 200px);
    }
    100%{
        transform: rotate3d(1, 1, -1, 45deg) translate3d(-87.72px, 47.378px, 200px);
    }
}
@keyframes float-horizontal-2 {
    0%{
        transform: rotate3d(1, 1, -1, 45deg) translate3d(-87.72px, 47.378px, 200px);
    }
    50%{
        transform: rotate3d(1, 1, -1, 45deg) translate3d(-107.72px, 47.378px, 200px);
    }
    100%{
        transform: rotate3d(1, 1, -1, 45deg) translate3d(-87.72px, 47.378px, 200px);
    }
}
@keyframes floatAnimationDown {
    from {
      transform: translateY(0);
    }
    to {
        transform: translateX(-40px) translateY(-60px);
    }
  }
@keyframes floatAnimationUp {
    from {
      transform: translateY(0);
    }
    to {
        transform: translateX(40px) translateY(60px);
    }
  }

@media(max-width:767px)
{
    #hero .contentHero{
        display: flex;
        align-items: baseline;
        padding: 10px;
        padding-top: 50px;
        height: 900px;
    }
    #hero .container h1{
        width: 100%;
        font-size: 34px;
    }
    #hero .container {
        width: 100%;
        align-items: center;
        h1,p{
            text-align: center;
        }
        .contentBtn{
            justify-content: center;
        }
    }
    .heroImg1{
        width: 24%;
    }
    .heroImg2{
        width: 65%;
    }
    .heroImg3{
        width: 40%;
    }
    .heroImg4{
        width: 120%;
    }
    .heroImg5{
        width: 75%;
    }
}