.accordion-item {
    border: 1px solid #ccc;
    margin-bottom: 10px;
  }
  
  .accordion-header {
    background-color: #f1f1f1;
    cursor: pointer;
    padding: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .accordion-content {
    // overflow: hidden;
  }
  
  .icon-container {
    transition: transform 0.3s ease;
  }
  
  .icon-container.open {
    transform: rotate(180deg);
  }
  