.nav{
    display: flex;
    justify-content: space-between;
    align-items: center;
    // position: fixed;
    // z-index: 123;
    // top: 0;
    // left: 0;
    // right: 0;
    background-color: $background-color-primary;
    width: 100%;
    padding: 10px 0px;
    .HeaderNav{
        display: none;
    }
    .logo{
        width: 25%;
        display: flex;
        justify-content: end;
    }
    .menu{
        background-color: $box-color;
        padding: 13px;
        border-radius: 90px;
        display: flex;
        width: 20%;
        svg{
            fill: $primary-color;
        }
    }
    ul{
        margin: 0;
        display: flex;
        align-items: center;
        gap: 20px;
        li{
            margin: 0;
            a{
                color: $title-color;
                font-weight: 600;
                &:hover{
                    color: $accent-color;
                }
            }
        }
    } 
    .pcLeft{
        width: 25%;
    }
    .pcRiht{
        width: 50%;
        display: flex;
    justify-content: flex-end;
    }
    
}
.celNavText{
    display: none;
}
.activeNavCel{
    position: fixed;
    background-color: $background-color-primary;
    height: 100vh;
    overflow-y: scroll;
    width: 40%!important;
    top: 0;
    left: 0;
    bottom: 0;
    z-index: 9999;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    text-align: start;
    padding: 40px!important;
    justify-content: flex-start!important;
    gap: 30px;
    display: flex;
    align-items: stretch;
    .celNavText{
        display: block;
    }
    .logo{
        display: none!important;
    }
    .menu{
        display: none!important; 
    }
    ul{
        display: flex !important;
        flex-direction: column;
        justify-content: start;
        align-items: start !important;
        text-align: start;
        width: 100%;
        gap: 30px!important;
    }
    .HeaderNav{
        display: flex!important;
        justify-content: start;
        width: 100%;
        h4{
            margin: 0;
        }
        .close{
            position: absolute;
            top: 5px;
            right: 5px;
            padding: 10px;
            background-color: $box-color;
            border-radius: 90px;
            width: 20px;
            height: 20px;
        }
    }
    .pcNavText{
        display: none!important;
    }
    .celNavText{
        display: flex!important;
    }
    .celNavTextBtn{
        display: none;
    }
}
@media(min-width: 768px)
{
    .nav{
        .menu{
            display: none;
        }
    }
    .pcNavText{
        display: block;
    }
    .celNavText{
        display: none!important;
    }
    .celNavTextBtn{
        display: none;
    }
}
@media(max-width: 767px)
{
    .nav .logo {
        display: flex;
        margin: auto;
        justify-content: center;
        width:50%;
    }

    .pcNavText{
        display: none!important;
    }
    .celNavText{
        display: none!important;
    }
    .celNavTextBtn{
        display: block;
        width: 30%;
    }
}

