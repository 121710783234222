
//SECTION
section{
  margin: 80px 0px;
}
.center-hor{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.titleSection{
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 40px;
  h6{
    display: flex;
    gap: 10px;
    justify-content: center;
    margin: 0;
    align-items: center;
  }
  h2{
    color: $title-color;
    text-align: center;
    width: 80%;
    text-align: center;
    margin: auto;
  }
  p{
      width: 65%;
      text-align: center;
      margin: auto;
  }
}
.titleSectionStart{
  align-items: start;
  h2{
    width: 100%;
    text-align: start;
  }
  p{
    width: 100%;
    text-align: start;
  }
}
.titleSectionWhite{
  display: flex;
    flex-direction: column;
    text-align: center;
  h2{
    color: $title-color-white;
  }
  h6{
    color: $accent-color;
  }
  p{
    color: $text-color-white;
  }
}

$h1-font-size: (
  font-size: 55px,
  line-height:  1.3, 
);
$h2-font-size: (
  font-size: 42px,
  line-height:  1.3, 
); 
$h3-font-size: (
  font-size: 28px,
  line-height:  1.3, 
);
$h4-font-size: (
  font-size: 24px,
  line-height:  1.3, 
);
$h5-font-size: (
  font-size: 20px,
  line-height:  1.3, 
);
$h6-font-size: (
  font-size: 16px,
  line-height:  1.3, 
);


// Responsive max width 767px
$h1-font-size-767px: (
  font-size: 35px,
  line-height:  1.3, 
);
$h2-font-size-767px: (
  font-size: 28px,
  line-height:  1.3, 
);
$h3-font-size-767px: (
  font-size: 24px,
  line-height:  1.3, 
);
$h4-font-size-767px: (
  font-size: 20px,
  line-height:  1.3, 
);
$h5-font-size-767px: (
  font-size: 16px,
  line-height:  1.3, 
);
$h6-font-size-767px: (
  font-size: 12px,
  line-height:  1.3, 
);

body {
    font-family: $font-family;
    font-size: $base-font-size;
    color: $text-color;
    background-color: $background-color-primary;
    line-height: 1.6;
    margin: 0;
    padding: 0;
  }
  
  // Encabezados
  h1, h2, h3, h4, h5, h6 {
    color: $title-color;
    margin:10px 0px;
    font-family: $font-family!important;
  }
  
  h1 {
    font-size: map-get($h1-font-size, font-size);
    line-height: map-get($h1-font-size, line-height);
  }
  h2 {
    font-size: map-get($h2-font-size, font-size);
    line-height: map-get($h2-font-size, line-height);
  }
  h3 {
    font-size: map-get($h3-font-size, font-size);
    line-height: map-get($h3-font-size, line-height);
  }
  h4 {
    font-size: map-get($h4-font-size, font-size);
    line-height: map-get($h4-font-size, line-height);
  }
  h5 {
    font-size: map-get($h5-font-size, font-size);
    line-height: map-get($h5-font-size, line-height);
  }
  h6 {
    font-size: map-get($h6-font-size, font-size);
    line-height: map-get($h6-font-size, line-height);
  }
  
  // Enlaces
  a {
    color: $link-color;
    text-decoration: none!important;
    &:hover {
      color: $link-hover-color;
      text-decoration: underline;
    }
  }
  
  // Listas
  ul {
    list-style-type: none;
    padding: 0;
  }
  
  li {
    margin-bottom: $small-spacing;
  }
  





.w-100{
    width: 100%;
}
.mb-10{
    margin-bottom: 10px;
}
.mt-10{
    margin-top: 10px;
}
.ml-10{
    margin-left: 10px;
}
.mr-10{
    margin-right: 10px;
}
.row {
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
}
.container {
    max-width: 1200px;
    padding: 10px;
    margin-right: auto;
    margin-left: auto;
}
.container-fluid {
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}
.flex {
  display: flex;
}
.column {
  flex-direction: column;
}
.between{
  justify-content: space-between;
}
.hor-end{
  justify-content: end;
}
.text-end{
  text-align: end;
}
.hor-center{
  justify-content: center;
}
.hor-start{
  justify-content: start;
}
.gap-5{
  gap: 5px;
}
.gap-10{
    gap: 10px;
}
.gap-20{
    gap: 20px;
}
.ver-end{
  align-items: end;
}
.ver-center{
  align-items: center;
}
.ver-start{
  align-items: start;
}
.around{
  justify-content: space-around;
}
.between{
  justify-content: space-between;
}
.evenly{
  justify-content: space-evenly;
}
.width-100{
  width: 100%;
}
.text-center{
  text-align: center;
}
.bg-primary{
  background-color: $primary-color;
}
.label-reset{
  label{
    margin: 0;
    padding: 0;
  }
}


  //RESPONSIVE
  @media (max-width: 767px)
  {
    section{
      .titleSection{
        h1{
          width: 100%;
        }
        p{
          width: 100%;
        }
      }
    }
    .titleSection h2{
      width: 100%;
    }
    h1 {
      font-size: map-get($h1-font-size-767px, font-size);
      line-height: map-get($h1-font-size-767px, line-height);
    }
    h2 {
      font-size: map-get($h2-font-size-767px, font-size);
      line-height: map-get($h2-font-size-767px, line-height);
    }
    h3 {
      font-size: map-get($h3-font-size-767px, font-size);
      line-height: map-get($h3-font-size-767px, line-height);
    }
    h4 {
      font-size: map-get($h4-font-size-767px, font-size);
      line-height: map-get($h4-font-size-767px, line-height);
    }
    h5 {
      font-size: map-get($h5-font-size-767px, font-size);
      line-height: map-get($h5-font-size-767px, line-height);
    }
    h6 {
      font-size: map-get($h6-font-size-767px, font-size);
      line-height: map-get($h6-font-size-767px, line-height);
    }
    
  }

