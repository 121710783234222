select {
      border: 2px solid $border;
      padding: 20px 15px;
      font-size: 14px;
        font-family: 'Inter';
        width: 100%;
        &:focus{
          outline: none;
          border: 2px solid $border-focus;
        }
    }
    .active{
        color: $accent-color!important;
    }
    .alert {
      padding: 15px;
      margin-bottom: 20px;
      border: 1px solid transparent;
      border-radius: 4px;
    }
    .etiquetas{
      display: flex;
      padding: 20px;
      gap: 20px;
      justify-content: space-between;
      h6{
        color: $text-color;
        font-size: 13px;
        text-transform: uppercase;
        font-weight: 500;
        margin-bottom: 10px;
      }
      h3{
        color: $title-color;
        font-size: 25px;
      }
      div:last-child{
        display: flex;
        align-items: end;
        justify-content: end;
        img{
          padding: 13px;
          background: #DAF4F0;
          border-radius: 8px;
          width: 60px;
          height: 60px;
          }
        }
      }
    .form-group{
      margin-top: 20px;
    }
    .alert-success {
      color: #3c763d;
      background-color: #dff0d8;
      border-color: #d6e9c6;
    }
    
    .alert-danger {
      color: #a94442;
      background-color: #f2dede;
      border-color: #ebccd1;
    }
    
    .bg-red{
      background-color: #a94442;
    }
    .table-responsive{
      overflow: hidden;
      overflow-x: scroll;
      width: 100%;
    }
    
    #table-normal{
       border-collapse: collapse;
      thead{
    
        background: $box-color;
        th{
          padding: 10px;
          font-size: 14px;
          font-weight: 600;
        }
      }
      tbody{
        td{
          border: 1px solid $border;
          padding: 0px 10px;
          font-size: 13px;
        }
      }
    }
    .group-input{
      position: relative;
    }
    .required{
      input{
        border-right: 3px solid $accent-color;
      }
    }
    input {
      border-radius: 0px;
      padding: 15px;
      padding: 20px 15px;
      border-radius: 90px;
      font-size: 14px;
      width: -webkit-fill-available;
      color: $text-color;
      font-family: $font-family;
        background: #f6f6f6;
        border: 1px solid #ffffff00;
        &:focus{
          outline: none;
          border: 1px solid $primary-color;
          //border-bottom: 3px solid $primary;
        }
    }
    
    textarea {
        padding: 20px;
        border: 2px solid $border;
        padding: 15px;
        padding: 20px 15px;
        font-size: 14px;
        font-family: sans-serif;
          width: 100%;
          white-space: pre-wrap;
    
          &:focus{
            outline: none;
            border: 1px solid $primary-color;
            //border-bottom: 3px solid $primary;
          }
      }
    label {
      position: relative;
      color: $title-color;
      z-index: 2;

      display: flex;
      place-self: flex-start;
      padding: 0px 0px;
      margin-left: 0px;
      margin-bottom: 5px;
      font-weight: 600;
      font-size: 14px;
      width: fit-content;
    
    }
    select{
      &:focus{
        outline: none;
        border: 1px solid #acacac;
      }
    }
    .input-icon-left{
        position: relative;
        img{
          position: absolute;
          z-index: 2;
          top: 35%;
          left: 35px;
          transform: translate(-50%);
          width: 20px;
        }
        input{
          padding-left: 60px;
        }
    }
    /* Estilo base para todos los checkboxes */
    .checkbox-personalizado {
      display: inline-block;
      width: 20px;
      height: 20px;
      background: white;
      border-radius: 3px;
      border: 2px solid $primary-color;
      position: relative;
    }
    
    .checkbox-personalizado::after {
      content: "";
      display: none;
      position: absolute;
      width: 8px;
      height: 8px;
      background-color: $primary-color;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    
    input[type="checkbox"]:checked ~ .checkbox-personalizado::after {
      display: block;
    }
