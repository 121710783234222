@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');
html {
    scroll-behavior: smooth;
    overflow-y: scroll!important;
  }
// Colores
$primary-color: #0A0A0D;
$secondary-color: #BAFD00;
$accent-color: #BAFD00;
$background-color-primary: #fff;
$background-color-secondary: #0A0A0D;
$text-color: #666666;
$text-color-white: #969696;
$title-color-white: #fff;
$title-color: #0A0A0D;
$link-color: #BAFD00;
$link-hover-color: darken($link-color, 10%);
$button-color: #0A0A0D;
$white: #fff;
// $button-hover-color: darken($button-color, 10%);
$button-hover-color: #BAFD00;
$border-color-white: #fff;
$border-color: #BAFD00;
$border: #F0F0F0;
$border-focus: #BAFD00;
$box-color: #F6F6F6;
$footer-color: #F6F6F6;

// Tipografía
$font-family: 'Inter';
$base-font-size: 16px;

// Espaciado
$base-spacing: 16px;
$small-spacing: 8px;
$large-spacing: 24px;
$vertical-spacing-button: 15px;
$horizontal-spacing-button: 30px;



