@mixin bg-blur($bg1,$bg2){
        background-color: $background-color-secondary;
        overflow: hidden;
        position: relative;
    &::before{
        content: "";
        width: 120px;
        height: 120px;
        background-color: $bg1;
        position: absolute;
        border-radius: 20px;
        top: 0;
        left: 0;
        filter: blur(100px);
        animation: floatAnimationUp 2s infinite alternate;
    }
    &::after{
        content: "";
        width: 120px;
        height: 120px;
        background-color: $bg2;
        position: absolute;
        border-radius: 20px;
        bottom: 0;
        right: 0;
        filter: blur(100px);
        animation: floatAnimationDown 2s infinite alternate;
    }
}
