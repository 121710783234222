 .planes{
    .card{
        background-color: $box-color;
        padding: 20px;
        border-radius: 10px;
        margin: 0px 10px;
        .cardTitle{
            h3{
                margin: 0;
                font-size: 25px;
            }
            p{
                margin: 10px 0px 20px 0px;
                font-size: 14px;
            }

        }

        .infoActive{
            display: none;
            font-size: 13px !important;
            // color: #adadad;
            padding: 12px;
            background: #fff;
            position: absolute;
            box-shadow: 0px 0px 30px 0px #0000002e;
            border-radius: 7px;
        }
        .info{
            
            &:hover{
                cursor: pointer;
                .infoActive{
                    display: flex;
                }
            }
        }
        .textList{
            display: flex;
            gap: 5px;
        
        }
        

        .cardBody{
            position: relative;
            z-index: 2;
            .accordion{
                margin-bottom: 20px;
                .accordion-header{
                    border-radius: 10px;
                    transition: 300ms;
                    svg{
                        fill: $title-color;
                    }
                    &:hover{
                        background-color: $accent-color;
                        transition: 300ms;
                    }
                }
                .accordion-item{
                    background-color: $box-color;
                    border: none;
                    padding: 0px 0px;
                    h4{
                        font-size: 16px;
                        margin: 0;
                    }
                }
                .accordion-content{
                    background-color: $background-color-primary;
                    padding: 16px;
                    border-radius: 1px;
                    display: flex;
                    flex-direction: column;
                    gap: 10px;
                    border-radius: 10px;
                    p{
                        margin: 0;
                        font-size: 15px;
                    }
                }              
            }
            .precio{
                margin: 0;
                margin-bottom: 10px;
                font-size: 22px;
                span{
                    font-size: 10px;
                }
            }
            button{
                margin-top: 20px;
            }
        }
    }
 }
 .cardSelect{
    background-color: $background-color-secondary!important;
    overflow: hidden;
    position: relative;
    &::before{
        content: "";
        width: 120px;
        height: 120px;
        background-color: $secondary-color;
        position: absolute;
        border-radius: 20px;
        top: 0;
        left: 0;
        filter: blur(100px);
        animation: floatAnimationUp 2s infinite alternate;
    }
    &::after{
        content: "";
        width: 120px;
        height: 120px;
        background-color: $secondary-color;
        position: absolute;
        border-radius: 20px;
        bottom: 0;
        right: 0;
        filter: blur(100px);
        animation: floatAnimationDown 2s infinite alternate;
    }
    h3,h4,h6{
        color: $title-color-white!important;
    }
    p{
        color: #C5C5C5!important;
    }
    .infoActive{
        background-color: #3d3d3d!important;
    }
    .accordion-header{
        background: #ffffff00!important;
        transition: 300ms;
        svg{
            fill: $title-color-white!important;
        }
        &:hover{
            background-color: $accent-color!important;
            h4{
                color: $title-color!important;
            }
            svg{
                fill: $title-color!important;
            }
        }
    }
    .accordion-item{
        background: #ffffff00!important;
    }
    .accordion-content{
        background-color: #4040457a!important;
    }
 }