.gridProyect{
    a{
        display: block;
        background: #fff;
        border-radius: 5px;
        -webkit-box-shadow: 0 0 20px rgba(0, 0, 0, 0.07);
        box-shadow: 0 0 20px rgba(0, 0, 0, 0.07);
        position: relative;
        z-index: 5;
        overflow: hidden;
        padding: 20px;
    }
    img{
        width: 100%;
        height: 300px;
        -o-object-fit: cover;
        object-fit: cover;
        -o-object-position: top;
        object-position: top;
        -webkit-transition: all 7s linear;
        -o-transition: all 7s linear;
        transition: all 7s linear;
    }
    span{
        font-size: 14px;
        color: #9fa0b0;
        margin-top: 20px;
    }
    h6{
        margin: 5px 0px;
    }
    .card{
        position: relative;
        padding: 10px;
        overflow: hidden;
        &:hover  img{
            -o-object-position: bottom;
            object-position: bottom;
        }
    }
}
.filter-buttons{
    overflow-x: scroll;
    width: 100%;
    padding-bottom: 10px;
    button{
        width: fit-content;
        min-width: 200px;
    }
}