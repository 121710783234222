#TodoIncluido {
    margin: 0;
    margin-top: 90px;
    .grid-2{
        align-items: center;
    }
    .drh{
        display: flex;
        img{
            width: 100%;
        }
    }
}