.contacto{
    .container {
        .grid-2{
            .drh{
                display: flex;
                justify-content: end!important;
            }
            align-items: center;
            label{
                font-size: 14px;
                color: $title-color;    
                font-weight: 600;
            }
            input{
                padding: 20px 20px;
                background-color: #ffffff;
                border: none;
                border-radius: 90px;
                min-width: -webkit-fill-available;
            }
            form{
                h6{
                    margin: 0;
                }
                justify-content: center;
                align-items: center;
                display: flex;
                flex-direction: column;
                background: #f6f6f6;
                padding: 40px;
                border-radius: 10px;
                width: 400px;
                .contentInput{
                    display: flex;
                    flex-direction: column;
                    align-items: start;
                    gap: 5px;
                    margin-top: 18px;
                    width: 100%;
                }
                .contentPoliticas{
                    display: flex;
                    justify-content: start;
                    width: 100%;
                    margin-top: 10px;
                    gap: 5px;
                    label{
                        color: $text-color;
                        font-weight: 500;
                    }
                    input{
                        width: 15px;
                        height: 15px;
                        min-width: auto;
                    }
                    a{
                        color: $title-color!important;
                        font-weight: 600;
                        margin-left: 5px;
                    }
                }
                button{
                    width: 100%;
                    margin-top: 18px;
                    padding: 20px;
                }
            }
            .mensaje{
                background: #d0f8d0;
                padding: 20px;
                border-radius: 10px;
                font-size: 14px;
                text-align: center;
            }
        }
    }
}

@media(max-width:767px){
    .contacto .container .grid-2 form{
        width: 90%!important;
    }
}