.administrable{
    margin: 0!important;
    .drh{
        justify-content: start;
    }
    .titleSectionStart{
        align-items: end;
    }
    h2,p{
        text-align: end;

    }

    .contetVideoDiseño{
        width: 100%;
        height: 540px;
        video{
            top: 18px;
            width: 209px;
            height: 438px;
        }
        .imgAdmin{
            position: absolute;
            bottom: 0;
            left: 0;
        }
    }
}