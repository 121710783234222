.trabajo{

    .box{
    background-color: $box-color;
    border-radius: 12px;
    padding: 22px;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 15px;
    h3{
        font-size: 17px;
        margin: 0;
    }
    span{
        align-self: flex-end;
        top: 0px;
        right: 15px;
        font-size: 40px;
        font-weight: 700;
        color: $title-color;
        line-height: 40px;
        margin-bottom: -20px;
    }
    p{
        margin: 0;
        font-size: 14px;
    }

    }
    .boxBlack{
        background-color: $background-color-secondary;
        @include bg-blur(#bafd0052, #ffffff4a);
        h3,span{
            color: $title-color-white;
        }
        p{
            color: $text-color-white;
        }
    }
}