.WhatsappFlotante{
    position:fixed;
    bottom: 20px;
    z-index: 999999;
    right: 20px;
    img{
        width: 86px;
    }
}

@media(max-width: 767px)
{
    .WhatsappFlotante img {
        width: 60px;
    }
}