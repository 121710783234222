  // Pie de página
  footer {
    padding: 10px;
    .container{
        background-color: $footer-color;
        border-radius: 20px;
    }
    .contentFooter{
      background-color: $footer-color;
      border-radius: 20px;
    }
    .grid-4{
      gap: 25px;
      padding: 80px 40px;
    }
    .infoTop{
      padding: 20px 0px;
      p{
        text-align: center;
        font-size: 14px;
        margin: 0;
      }
    }
    .titleBox{
      h4{
        margin:  10px 0px; 
      }
      svg{
        display: flex;
      }
      margin-bottom: 30px;
    }
    .box{
      display: flex;
      flex-direction: column;
      justify-content: start;
    }
    ul{
      margin: 0;
    }
    a {
      color: $text-color;
      text-decoration: underline;
      display: flex;
      align-items: center;
      gap: 8px;
      svg{
        fill: $primary-color;
      }
      &:hover {
        text-decoration: none;
      }
    }
  }

  @media(max-width:767px)
{
  footer .grid-3 {
    padding: 80px 0px;
}
}