 .diseño{
    margin: 0;
    // .bgSection{
    //     background: rgb(10,10,13);
    //     background: linear-gradient(270deg, rgb(10, 10, 13) 30%, rgb(255 255 255) 30%); 
    //     padding: 50px 0px;
    //     position: relative;
    //     overflow: hidden;
    //     &::before{
    //         content: "";
    //         width: 120px;
    //         height: 120px;
    //         background-color: $secondary-color;
    //         position: absolute;
    //         border-radius: 20px;
    //         top: 0;
    //         right: 30%;
    //         filter: blur(100px);
    //         animation: floatAnimationUp 2s infinite alternate;
    //     }
    //     &::after{
    //         content: "";
    //         width: 120px;
    //         height: 120px;
    //         background-color: $secondary-color;
    //         position: absolute;
    //         border-radius: 20px;
    //         bottom: 0;
    //         right: 0;
    //         filter: blur(100px);
    //         animation: floatAnimationDown 2s infinite alternate;
    //     } 
    // }
    .grid-2{
        align-items: center;
    }
    .drh{
        display: flex;
    justify-content: end;
    }
    .contetVideoDiseño{
        width: 100%;
        height: 490px;
        border-radius: 10px;
        overflow: hidden;
        justify-content: center;
        align-items: center;
        display: flex;
        position: relative;
        video{
            object-fit: cover;
            object-position: center;
            width: 355px;
            height: 230px;
            z-index: 0;
            top: 70px;
            position: absolute;
        }
        img{
            
            position: relative;
            z-index: 1;
            margin-top: 20px;
        }
        .card1{
            position: absolute;
            top: 0px;
            right: 0;
        }
        .card2{
            position: absolute;
            top: 140px;
            right: 10px;
        }
    }
}
@media (max-width: 767px){

}
@media (max-width: 767px)
{
    .diseño{
        margin: 0;
        margin-top: 40px;
    }
    .administrable{
        margin: 0px 0px;
    }
    .administrable .grid-2{
        display: flex;
        flex-direction: column-reverse;
    }
    .diseño .contetVideoDiseño img{
        width: 358px;
    }
    .diseño .contetVideoDiseño .card1{
        width: 200px;
    }
    .diseño .contetVideoDiseño .card2{
        top: 112px;
        right: 10px;
        width: 170px;
    }
    .diseño .contetVideoDiseño video{
        width: 280px;
        height: 180px;
        top: 107px;
    }
    img.imgAdmin {
        width: 170px !important;
    }
    .administrable .contetVideoDiseño .videoDesign{
        width: 162px;
        height: 345px;
        top: 73px;
    }
}

