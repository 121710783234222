.portafolio {
  margin: 130px 0px;
  .row {
    display: flex;
    flex-wrap: nowrap;
    width: 100%;
    gap: 20px;
    flex-direction: row-reverse;
    overflow: hidden;
  }
  .title{
    display: flex;
    justify-content: start;
    gap: 10px;
    align-items: center;
    h2{
      font-size: 25px;
    }
    .circle{
      width: 10px;
      height: 10px;
      border-radius: 90px;
      background-color: $accent-color;
    }
  }
  .contentIzq {
    width: 22%;
    padding: 20px;
    border-radius: 10px;
    @include bg-blur(#bafd0052, #ffffff4a);
    h2{
      color: $title-color-white;
    }
  }
  .contentDrh {
    padding: 20px;
    border-radius: 10px;
    background-color: $box-color;
    width: 78%;
    overflow: hidden;
    .borderIdentificate{
      width: 100%;
      height: 20px;
      border-radius: 90px;
    }
  }
  .detail {
    display: flex;
    justify-content: space-between;
    .color{
      display: flex;
      gap: 5px;
        div{
          width: 38px!important;
          height: 38px!important;
          border-radius: 90px;
        }
    }
    p{
      margin: 0px;
      font-size: 15px;
    }
  }
  .contentImg {
    display: flex;
    gap: 20px;
    overflow-x: scroll;
    width: 100%;
    margin-bottom: 20px;
    padding: 20px 0px;

  }
  
  .boxImg {
    width: 182px;
    height: 233px;
    border-radius: 10px;
    background-color: #D9D9D9;
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 182px;
    overflow: hidden;
    box-shadow: 0px 4px 4px 0px #00000014;
    transition: 300ms;
    img{
      width: 100%;
      height: 233px;
      object-fit: cover;
      cursor: pointer;
      object-position: top;
      &:hover{
        transform: scale(1);
      }
    }
    svg {
      font-size: 40px;
    }
  }
  
  .slick-slider{
    height: 100%;
  }
}





.portafolio .slider ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.portafolio .slider ul li {
  display: flex;
  align-items: start;
  justify-content: start;
  font-size: 15px;
  cursor: pointer;
  position: relative;
  padding-left: 20px;
  transition: transform 0.3s ease;
  margin-bottom: 12px;
  color: $text-color-white;
}

.portafolio .slider ul li.active {
  font-weight: 600;
  color: $title-color-white;
  transition: top 0.3s ease;
  &::before{
    content: "";
    width: 10px;
    height: 10px;
    background-color: $accent-color;
    position: absolute;
    top: 30%;
    left: 0px;
    border-radius: 90px;
    transition: top 0.3s ease;
    
  }
}

.other-content {
  height: 100vh;
  background-color: #f5f5f5;
  padding: 20px;
}







//MODAL
.modal {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.8);
}

.slick-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.close {
  position: absolute;
  top: 15px;
  right: 35px;
  color: #fff;
  font-size: 40px;
  font-weight: bold;
  cursor: pointer;
}

.sliderContainer {
  width: 50%;
  height: 500px;
  border-radius: 10px;
  background: #fff;
  overflow: hidden;
  .slick-prev{
    fill: $accent-color;
    padding: 10px 6px 10px 13px;
    background: $primary-color;
    border-radius: 90px;
    display: flex !important;
    align-items: center;
    justify-content: center;
    left: 10px;
    transition: 300ms;
    &:hover{
      background-color: $accent-color;
      fill: $primary-color;
    }
  }
  .slick-next{
    right: 10px;
    fill: $accent-color;
    padding: 10px;
    background: $primary-color;
    border-radius: 90px;
    display: flex !important;
    align-items: center;
    justify-content: center;
    transition: 300ms;
    &:hover{
      background-color: $accent-color;
      fill: $primary-color;
    }
  }
  .slick-dots{
    bottom: 25px !important;
  }
}

.modal-slide {
  display: flex !important;
  align-items: center;
  justify-content: center;
}

.slider-arrow {
  font-size: 30px;
  color: white;
}

.slider-arrow-prev {
  position: absolute;
  left: -50px;
  z-index: 1;
}

.slider-arrow-next {
  position: absolute;
  right: -50px;
  z-index: 1;
}




@media (max-width: 767px) {
  .portafolio {
    .row {
      width: 100%;
      display: flex; 
      flex-direction: column;
    }
    .contentIzq {
      width: 89%;
      }
      .contentDrh {
        width: 89%;
      }
  }
}
