
    .grid-2 {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(48%, 1fr));
      gap: 20px;
    
    }
    .grid-3 {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(28%, 1fr));
      gap: 20px;
    }
    .grid-4 {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(23%, 1fr));
      gap: 20px;
    }
    .grid-5 {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(18%, 1fr));
      gap: 20px;
    }
    .grid-6 {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(14%, 1fr));
      gap: 20px;
    }
    
    @media (max-width: 767px) {
      /* Estilos para pantallas pequeñas */
      .grid-2,
      .grid-3,
      .grid-4,
      .grid-5,
      .grid-6 {
    grid-template-columns: repeat(1, 1fr); /* Siempre 2 columnas */
      }
      .container{
        padding: 0px 10px;
      }
    }
    @media (min-width: 768px) and (max-width: 1024px) {
      /* Estilos para tabletas en modo vertical */
    
    }
    
    @media (min-width: 1025px) and (max-width: 1366px) {
      /* Estilos para tabletas en modo horizontal (paisaje) */
    
    }
    @media (min-width: 1367px) {
      /* Estilos para computadoras de escritorio */
    
    }
    