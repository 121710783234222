#ingreso{
    background-image: url('https://img.freepik.com/foto-gratis/representacion-experiencia-usuario-diseno-interfaz_23-2150169845.jpg?t=st=1721581947~exp=1721585547~hmac=9ccdd9b90095feb758311ca9a826a423bb9bce321d1e36c8c020a5970809267d&w=1380');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 100vh;
    margin: 0;
    form{
        h1{
            font-size: 30px;
        }
        p{
            margin-bottom: 30px;
        }
        text-align: center;
        padding: 40px;
        border-radius: 10px;
        background-color: $background-color-primary;
        width: 400px;
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        box-shadow: -1px 0px 20px 2220px #0000003d;
        button{
            padding: 20px;
            display: flex;
            width: 100%;
        }
        .toggle-password{
            padding: 10px;
            cursor: pointer;
            width: 20px;
            height: 20px;
            border-radius: 90px;
            justify-content: center;
            display: flex;
            align-items: center;
            position: absolute;
            top: 17%;
            right: 12px;
        }
        .password-wrapper{
            position: relative;
        }
        .rememberMe{
            margin-bottom: 15px;
            label{
                cursor: pointer;
            }
            input{
                cursor: pointer;
                width: 20px;
            }
        }
        .olvideContra{
            color: $title-color;
            font-size: 14px;
            margin-top: 20px;
            font-weight: 600;
            display: flex;
            justify-content: center;
        }
    }
}
@media (max-width: 767px)
{
    #ingreso form{
        width: 300px;
    }
}