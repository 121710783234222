.banner {
    margin: 0;
    margin-bottom: 80px;
  .bg-banner {
    background-color: $box-color;
    padding: 50px;
    margin: 10px;
    border-radius: 10px;
    .container {
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      flex-direction: column;
    }
  }
}
.articuloDestacado,
.listArticle {
  h3 {
    font-size: 40px;
    transition: 300ms;
    &:hover{
        color: $accent-color;
        cursor: pointer;
    }
  }
  .izq {
    overflow: hidden;
    border-radius: 10px;
    height: 400px;
    img {
      transition: 300ms;
      width: 100%;
      height: 400px;
      object-fit: cover;
      object-position: center;
      border-radius: 10px;
      &:hover {
        transform: scale(1.1);
        cursor: pointer;
      }
    }
  }
  .drh {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
  }
  p {
    margin: 0;
  }
  .fecha {
    display: flex;
    gap: 10px;
    align-items: center;
    span {
      font-size: 13px;
    }
    svg {
      width: 20px;
      fill: $title-color;
    }
  }
  .categoria {
    font-size: 15px;
    font-weight: 400;
    color: $text-color;
    span {
      font-weight: 700;
      color: $title-color;
    }
  }
}
.listArticle {
  .box {
    overflow: hidden;
    .img {
      overflow: hidden;
      border-radius: 10px;
      height: 300px;
      img {
        transition: 300ms;
        width: 100%;
        height: 300px;
        object-fit: cover;
        object-position: center;
        border-radius: 10px;
        &:hover {
          transform: scale(1.1);
          cursor: pointer;
        }
      }
    }
  }
  h3 {
    font-size: 22px;
  }
  .fecha {
    margin-top: 10px;
  }
}
