 // Botones
 button, .button {
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: center;
    padding: $vertical-spacing-button $horizontal-spacing-button;
    color: $title-color-white;
    font-size: 14px;
    font-weight: 700;
    background-color: $button-color;
    border: none;
    border-radius: 90px;
    text-align: center;
    cursor: pointer;
    transition: background-color 0.3s;
    svg{
      font-size: 18px;
    }
    &:hover {
      background-color: $button-hover-color;
      color: $title-color;
    }
  }
  h1{
    margin: 0;
  }
  .btnRevers{
    background-color: #ffffff00;
    border: 1px solid $border-color-white;
    color: $title-color-white;
    transition: background-color 0.3s;
    svg{
      fill: $secondary-color;
    }
    &:hover{
      color: $primary-color;
      border: 1px solid $secondary-color;
      svg{
        fill: $primary-color;
      }
    }
    }
  .btnBgBlack{
    background-color: $background-color-primary;
    color: $title-color;
  }

  @media(max-width: 767px){
    button, .button{
      font-size: 13px;
      padding: 15px 25px;
    }
  }